import { Fragment, useEffect, useState } from 'react'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  RocketLaunchIcon,
  CogIcon,
  HomeIcon,
  InboxIcon,
  UserPlusIcon,
  UsersIcon,
  IdentificationIcon,
  XMarkIcon,
  CheckIcon,
  ChevronUpDownIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { Dashboard } from './pages/dashboard';
import { useAuth } from './lib/auth';
import Login from './pages/login';
import { Engage } from './pages/engage';
import { useHistory } from 'react-router-dom';
import { BatchFollow } from './pages/batch-follow';
import { CompetitorPage } from './pages/competitor-page';
import { functions, httpsCallable, rtdb } from './hooks/firebase'
import { ref, child, onValue } from "firebase/database";
import Onboarding from './pages/onboarding';
import NewCampaign from './pages/modals/new-campaign';
import Settings from './pages/settings';
import { Help } from './pages/help';

// import { Fragment, useState } from 'react'
// import { , Transition } from '@headlessui/react'
// import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const people = [
  { id: 1, name: 'Empty' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function App() {

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [navigation, setNavigation] = useState([
    { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
    { name: 'Step 1 - Competitors', href: '/competitors', icon: IdentificationIcon, current: false },
    // { name: 'Step 2 - Batch Follow', href: '/batchFollow', icon: UserPlusIcon, current: false },
    { name: 'Step 2 - Engage', href: '/engage', icon: UsersIcon, current: false },
    { name: 'Settings', href: '/settings', icon: CogIcon, current: false },
    { name: 'Help', href: '/help', icon: InformationCircleIcon, current: false },
    // { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
  ])
  const auth = useAuth()
  const history = useHistory();
  const [userCampaigns, setUserCampaigns] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState(userCampaigns?.[0] || people[0])
  const [newCampaignModal, setNewCampaignModal] = useState(false)


  useEffect(() => {
    if (!auth.user) return;

    console.log(auth.user.localId);
    const defaultCampaign = ref(rtdb, `campaigns/${auth?.user?.localId}`);
    onValue(defaultCampaign, (snapshot) => {
      // console.log(snapshot);
      //iterate throu 
      const data = snapshot.val();
      console.log(data)



      if (data) {
        let campaigns = [];
        snapshot.forEach((childSnapshot) => {
          var childKey = childSnapshot.key;
          var childData = childSnapshot.val();
          console.log(childKey, childData);

          campaigns.push({
            id: childKey,
            name: childData.name,
            data: childData
          });
          setUserCampaigns([...campaigns]);
        });

      }else{
        setUserCampaigns([]);
      }
    });
  }, [auth])

  useEffect(() => {
    if (userCampaigns?.length > 0) {
      setSelectedCampaign(userCampaigns[0])
    }
  }, [userCampaigns])


  if (!auth.user) {
    navigation[0]['current'] = true;
    navigation[1]['current'] = false;
    navigation[2]['current'] = false;
    navigation[3]['current'] = false;
    navigation[4]['current'] = false;
    history.push('/')
    return (
      <Login />
    )
  } else {

    if (userCampaigns && userCampaigns.length == 0) {
      return (
        <Onboarding />
      )
    } else if(userCampaigns) {
      return (
        <>
          <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                          <button
                            type="button"
                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </button>
                        </div>
                      </Transition.Child>
                      <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                        <div className="flex flex-shrink-0 items-center px-4">
                          <img
                            className="h-8 w-auto"
                            src="/logo_no_background.png"
                            alt="DirectMarketer.ai"
                          />
                        </div>
                        <div>
                          <div className='w-8/12 inline-block'>

                            <Listbox value={selectedCampaign} onChange={setSelectedCampaign}>
                              {({ open }) => (
                                <>
                                  <Listbox.Label className="block text-sm font-medium text-white mt-5 space-y-1 px-2">Selected Campaign</Listbox.Label>
                                  <div className="relative mt-1 mx-2">
                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-gray-900 text-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                      <span className="block truncate">{selectedCampaign.name}</span>
                                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-700 text-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {userCampaigns?.map((person) => (
                                          <Listbox.Option
                                            key={person.id}
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'text-white bg-indigo-600' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                'relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer'
                                              )
                                            }
                                            value={person}
                                          >
                                            {({ selected, active }) => (
                                              <>
                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                  {person.name}
                                                </span>

                                                {selected ? (
                                                  <span
                                                    className={classNames(
                                                      active ? 'text-white' : 'text-indigo-600',
                                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                  >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                          <div className='w-4/12 inline-block'>
                            <button type="button"
                              onClick={() => {
                                console.log('clicked');
                                setNewCampaignModal(true);
                              }}
                              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                              Add
                            </button>
                          </div>
                        </div>
                        <nav className="mt-5 space-y-1 px-2">
                          {navigation.map((item) => (
                            <a
                              key={item.name}
                              onClick={() => {
                                history?.push(item.href);
                                navigation[0]['current'] = false;
                                navigation[1]['current'] = false;
                                navigation[2]['current'] = false;
                                navigation[3]['current'] = false;
                                navigation[4]['current'] = false;
                                navigation[navigation.indexOf(item)]['current'] = true;
                                setNavigation([...navigation])
                              }}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer'
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                  'mr-4 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                      <div className="flex flex-shrink-0 bg-gray-700 p-4">
                        <a href="#" className="group block flex-shrink-0">
                          <div className="flex items-center">
                            <div>
                              <img
                                className="inline-block h-10 w-10 rounded-full"
                                src={auth?.user?.photoURL}
                                alt=""
                              />
                            </div>
                            <div className="ml-3">
                              <p className="text-base font-medium text-white">{auth?.user?.displayName}</p>
                              <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                  <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
                <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="/logo_no_background.png"
                      alt="DirectMarketer.ai"
                    />
                  </div>
                  <div>
                    <div className='w-8/12 inline-block'>

                      <Listbox value={selectedCampaign} onChange={setSelectedCampaign}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-white mt-5 space-y-1 px-2">Selected Campaign</Listbox.Label>
                            <div className="relative mt-1 mx-2">
                              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-gray-900 text-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                <span className="block truncate">{selectedCampaign.name}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-700 text-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {userCampaigns?.map((person) => (
                                    <Listbox.Option
                                      key={person.id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'text-white bg-indigo-600' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                          'relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer'
                                        )
                                      }
                                      value={person}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                            {person.name}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? 'text-white' : 'text-indigo-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className='w-4/12 inline-block'>
                      <button type="button"
                        onClick={() => {
                          console.log('clicked');
                          setNewCampaignModal(true);
                        }}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                        Add
                      </button>
                    </div>
                  </div>
                  <nav className="mt-5 flex-1 space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        onClick={() => {
                          console.log(item.name);
                          if (item.name == "Upgrade") {
                            console.log(item.href + "?client_reference_id=" + auth?.user?.uid);
                            window.open(item.href + "?client_reference_id=" + auth?.user?.id, '_blank', 'noopener,noreferrer');
                            return;
                          }
                          history?.push(item.href);
                          navigation[0]['current'] = false;
                          navigation[1]['current'] = false;
                          navigation[2]['current'] = false;
                          navigation[3]['current'] = false;
                          navigation[4]['current'] = false;
                          navigation[navigation.indexOf(item)]['current'] = true;
                          setNavigation([...navigation])
                        }}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 bg-gray-700 p-4">
                  <a href="#" className="group block w-full flex-shrink-0">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-9 w-9 rounded-full"
                          src={auth?.user?.photoURL}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">{auth?.user?.displayName}</p>
                        <p onClick={auth.signout} className="text-xs font-medium text-gray-300 group-hover:text-gray-200">Sign Out</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col md:pl-64">
              <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
                <button
                  type="button"
                  className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <main className="flex-1">

                <Switch>
                  <Route path="/:path(|Home)">
                    <Dashboard selectedCampaign={selectedCampaign} />
                  </Route>
                  <Route path="/engage">
                    <Engage selectedCampaign={selectedCampaign} />
                  </Route>
                  <Route path="/competitors">
                    <CompetitorPage selectedCampaign={selectedCampaign} />
                  </Route>
                  <Route path="/batchFollow">
                    <BatchFollow selectedCampaign={selectedCampaign} />
                  </Route>
                  <Route path="/settings">
                    <Settings selectedCampaign={selectedCampaign} />
                  </Route>
                  <Route path="/help">
                    <Help/>
                  </Route>
                </Switch>

              </main>
            </div>
          </div>
          <NewCampaign isOpen={newCampaignModal} setNewCampaignModal={setNewCampaignModal}/>
        </>
      );
    }


  }
}

export default App;
