import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { httpsCallable } from 'firebase/functions'
import { functions, rtdb } from '../../hooks/firebase'
import { useAuth } from '../../lib/auth';
import { onValue, ref } from 'firebase/database'
import { RadioGroup } from '@headlessui/react'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export function OtherAccounts({
    selectedCampaignID
}) {

    const auth = useAuth();
    
    const [accounts, setAccounts] = useState([{
        id: 1,
        name: auth?.user?.displayName,
        avatar: auth?.user?.photoURL
    }])
    const [selected, setSelected] = useState(accounts[0])

    useEffect(() => {
        
        onValue(ref(rtdb, `users/${auth?.user?.localId}/accounts`), (snapshot) => {
            
            //console.log(snapshot.val())
            let newAccounts = [];
            newAccounts.push({
                id: 1,
                name: auth?.user?.displayName,
                avatar: auth?.user?.photoURL
            });
            
            if(snapshot.val() === null) {
                setAccounts([...newAccounts])   
                return;
            }
            for (const [key, value] of Object.entries(snapshot.val())) {
                // console.log(value);
                if(value['campaignID']==selectedCampaignID) newAccounts.push({
                    id: value['id'],
                    name: value['name'],
                    avatar: value['profile_image_url']
                });
            }

            
            setAccounts([...newAccounts])
            setSelected(newAccounts[newAccounts.length - 1])
        });
    }, [selectedCampaignID])
    
    return (
        <div className="mt-10 sm:mt-0">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Other Accounts</h3>
                        <p className="mt-1 text-sm text-gray-600">Add other Twitter accounts that you can use to engage users with</p>
                    </div>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                    <form action="#" method="POST">
                        <div className=" shadow sm:rounded-md">
                            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                <RadioGroup value={selected} onChange={setSelected}>
                                    <RadioGroup.Label className="block text-sm font-medium text-gray-700">Twitter Accounts</RadioGroup.Label>
                                    <div className="mt-4 flex items-center space-x-3">
                                        {accounts.map((color) => (
                                            <RadioGroup.Option
                                                key={color.name}
                                                value={color}
                                                className={({ active, checked }) =>
                                                    classNames(
                                                        color.selectedColor,
                                                        active && checked ? 'ring ring-offset-1' : '',
                                                        !active && checked ? 'ring-2' : '',
                                                        'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                                                    )
                                                }
                                            >
                                                <RadioGroup.Label as="span" className="sr-only">
                                                    {color.name}
                                                </RadioGroup.Label>
                                                <img src={color.avatar} alt="" className="h-10 w-10 flex-shrink-0 rounded-full" />
                                            </RadioGroup.Option>
                                        ))}
                                    </div>
                                </RadioGroup>

                            </div>
                            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                <button
                                    type="button"
                                    onClick={() => {
                                        let getCallbackURL = httpsCallable(functions, 'getCallbackURL');
                                        getCallbackURL({
                                            campaignID: selectedCampaignID
                                        }).then((result) => {
                                            console.log(result.data)
                                            window.open(result.data.url, "_blank", "noreferrer");
                                        });
                                    }}
                                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Add New
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
} 