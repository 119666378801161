// function component
import { onValue, ref, update } from 'firebase/database';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { rtdb } from '../../hooks/firebase';
import { useAuth } from '../../lib/auth';
import NewDMTemplate from '../modals/new-dm-template';


export function DMTemplates() {
    const auth = useAuth()
    const [DMTemplates, setDMTemplates] = useState([]);
    const [selectedDMTemplate, setSelectedDMTemplate] = useState();

    const [name, setName] = useState();
    const [text, setText] = useState();
    const [newDMTemplateModalOpen, setNewDMTemplateModalOpen] = useState(false);

    useEffect(() => {
        console.log("DM Tempalte Component");
        const campaignDMsRef = ref(rtdb, `users/${auth?.user?.localId}/DMTemplates`);
        onValue(campaignDMsRef, (snapshot) => {

            let localDMTemplates = []
            snapshot.forEach((childSnapshot) => {
                let dmTemplate = childSnapshot.val();
                dmTemplate['id'] = childSnapshot.key;
                localDMTemplates.push(dmTemplate)
            })

            setDMTemplates([...localDMTemplates])
            
            

        });
    }, [])

    return (
        <>
            <div className="">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">DM Templates</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                These templates can be used when you engaged in a conversation with a user.
                            </p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <form action="#" method="POST">
                            <div className="shadow sm:rounded-md">
                                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                    <div className="grid grid-cols-3 gap-6">
                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                                Your Templates
                                            </label>
                                            <select
                                                id="country"
                                                name="country"
                                                onChange={(e) => {
                                                    //get selected option id
                                                    console.log(e.target.options[e.target.selectedIndex].id)

                                                    //iterate through all the templates and find the one with the same id
                                                    let selectedTemplate = DMTemplates.find((dmTemplate) => {
                                                        return dmTemplate.id === e.target.options[e.target.selectedIndex].id
                                                    }
                                                    )
                                                    setSelectedDMTemplate(selectedTemplate)
                                                    setName(selectedTemplate.name)
                                                    setText(selectedTemplate.text)


                                                }}
                                                autoComplete="country-name"
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            >
                                                {DMTemplates.map((dmTemplate) => {
                                                    return <option
                                                        id={dmTemplate.id}
                                                        value={dmTemplate}
                                                        key={dmTemplate.id}>{dmTemplate.name}</option>
                                                })}
                                                {/* <option>United States</option>
                                                <option>Canada</option>
                                                <option>Mexico</option> */}
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                            Template Details
                                        </label>
                                        <div className="mt-1">
                                            <div className="grid grid-cols-3 gap-6">
                                                <input
                                                    type="text"
                                                    onChange={(e) => { setName(e.target.value) }}
                                                    name="email-address"
                                                    id="email-address"
                                                    value={name}
                                                    // defaultValue={selectedDMTemplate?.name}
                                                    autoComplete="email"
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>

                                            <textarea
                                                id="about"
                                                name="about"
                                                rows={3}
                                                onChange={(e) => { setText(e.target.value) }}
                                                value={text}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder="you@example.com"
                                            // defaultValue={selectedDMTemplate?.text}
                                            />
                                        </div>
                                        {/* <p className="mt-2 text-sm text-gray-500">
                                            Brief description for your profile. URLs are hyperlinked.
                                        </p> */}
                                    </div>
                                    <div className='text-right space-x-2'>
                                        <button
                                            onClick={() => {
                                                setNewDMTemplateModalOpen(true)

                                            }}
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Add New
                                        </button>
                                        <button
                                            onClick={() => {
                                                console.log("delete")

                                                const campaignDMsRef = ref(rtdb, `users/${auth?.user?.localId}/DMTemplates/${selectedDMTemplate.id}`);
                                                update(campaignDMsRef, {
                                                    name: name || selectedDMTemplate.name,
                                                    text: text || selectedDMTemplate.text
                                                })

                                            }}
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                        >
                                            Delete
                                        </button>
                                    </div>

                                </div>
                                {/* <div className="bg-white px-4 py-3 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Save
                                    </button>
                                </div> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>
            <NewDMTemplate isOpen={newDMTemplateModalOpen} setNewDMTemplateModalOpen={setNewDMTemplateModalOpen} />
        </>
    )
}