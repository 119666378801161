import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    TwitterAuthProvider,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    setDoc,
    doc,
    connectFirestoreEmulator,
} from "firebase/firestore";
import {
    getDatabase ,
    connectDatabaseEmulator
} from "firebase/database";

import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator
} from "firebase/functions";


const firebaseConfig = {
    apiKey: "AIzaSyB9x0RFL9cdaCjzA9liLegAKrzMuoNQcb0",
    authDomain: "directmarketer-ai.firebaseapp.com",
    databaseURL: "https://directmarketer-ai-default-rtdb.firebaseio.com",
    projectId: "directmarketer-ai",
    storageBucket: "directmarketer-ai.appspot.com",
    messagingSenderId: "1066991603332",
    appId: "1:1066991603332:web:bcc709ddebdc0276fe3cd1",
    measurementId: "G-BT16NK628T"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rtdb = getDatabase(app);
const functions = getFunctions(app);
// const router = useRouter();
// console.log(router);
// eslint-disable-next-line no-restricted-globals
// console.log(process.env);

if (shouldConnectAuthEmulator()) {
    //console.log("DEV??");
    // connectFunctionsEmulator(functions, "localhost", 5001);
    // connectDatabaseEmulator(rtdb, "localhost", 9000)
    // connectFirestoreEmulator(db,"localhost",8080)

    //set UID=yKMCuwSnHiM2fKT9fogQOXN1WqL2
    //firebase database:remove /backgroundProcesses/%UID% && firebase database:remove /campaigns/%UID% && firebase database:remove /completedEngage/%UID% && firebase database:remove /users/%UID%
}

function shouldConnectAuthEmulator() {
    // You could do any logic here to decide whether to connect to the emulator or not
    return process.env.NODE_ENV === 'development';
}

const googleProvider = new TwitterAuthProvider();
const signInWithTwitter = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        console.log(user);
        const docRef = doc(db, "users", user.uid);

        const data = {
            uid: user.uid,
            displayName: user.displayName,
            ...user['providerData'][0]
        };

        await setDoc(docRef, data)
        // const q = query(collection(db, "users"), where("uid", "==", user.uid));
        // const docs = await getDocs(q);
        // if (docs.docs.length === 0) {
        //     console.log("Empty");
        //     // await setDoc(collection(db, "users"), {
        //     //     uid: user.uid,
        //     //     name: user.displayName,
        //     //     // authProvider: "google",
        //     //     // email: user.email,
        //     // });
        // }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logout = () => {
    signOut(auth);
};

export {
    auth,
    db,
    rtdb,
    functions,
    httpsCallable,
    signInWithTwitter,
    logout,
};