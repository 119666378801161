import { functions, httpsCallable, rtdb } from '../../hooks/firebase'
import { useAuth } from '../../lib/auth';
import { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { EnvelopeIcon, TrashIcon as TrashIconMini } from '@heroicons/react/20/solid'
import { ref, child, onValue, orderByChild, equalTo, query, limitToLast, endBefore, endAt, startAfter } from "firebase/database";
import { useHistory } from 'react-router';


export default function Competitors({
    selectedCampaignID
}) {
    const auth = useAuth();
    const [competitorHandle, setCompetitorHandle] = useState();
    const [competitors, setCompetitors] = useState([])
    const history = useHistory();
    const [competitorExtractedFollowers, setCompetitorExtractedFollowers] = useState({})
    const [competitorExtractedFollowersWithOpenDMs, setCompetitorExtractedFollowersWithOpenDMs] = useState({})

    useEffect(() => {

        const competitorCountRef = query(ref(rtdb, `users/${auth?.user?.localId}/competitors`),orderByChild('campaignId'),equalTo(selectedCampaignID))
        onValue(competitorCountRef, (snapshot) => {
            const data = snapshot.val();
            let competitorsUpdated = [];
            if (!data) {
                setCompetitors([]);
                return;
            };
            for (const [key, value] of Object.entries(data)) {
                // console.log(value);
                competitorsUpdated.push(value)
            }
            //console.log(competitorsUpdated);
            setCompetitors([...competitorsUpdated])
        });

    }, [selectedCampaignID])

    useEffect(() => {
        //fore each competitor get followers with DMs open
        // console.log(competitors);
        competitors.forEach(competitor => {

            console.log(competitor.name);

            const userObject = query(ref(rtdb, `/users/${auth?.user?.localId}/competitorFollowers/${competitor['id']}`), orderByChild('can_dm'), startAfter(null));
            onValue(userObject, (snapshot) => {

                const data = snapshot.val();
                // console.log(data);
                //get number of keys in data object
                let count = 0;
                for (const [key, value] of Object.entries(data)) {
                    count++;
                }

                let countOpenDMs = 0;
                for (const [key, value] of Object.entries(data)) {
                    if(value['can_dm'])countOpenDMs++;
                }
                // console.log(count);
                competitorExtractedFollowers[competitor['id']] = count;
                setCompetitorExtractedFollowers({...competitorExtractedFollowers})

                competitorExtractedFollowersWithOpenDMs[competitor['id']] = countOpenDMs;
                setCompetitorExtractedFollowersWithOpenDMs({...competitorExtractedFollowersWithOpenDMs})

            })
        });


    }, [competitors.length])

    function itsBeen15MinutesSinceLastAdd(){
        let output = false;

        if(competitors.length==0) return true;
        competitors.forEach(user => {
            let dif = new Date().getTime()-user['createdAt'];
            //console.log();
            if((dif/(1000*60)) > 15) output = true;
        });
        return output;
    }

    function competitorAlreadyExists(){
        let output = false;
        competitors.forEach(user => {
            // console.log(competitorHandle.replaceAll("@", '') === user['username']);
            if(competitorHandle.replaceAll("@", '') === user['username']) output = true;
        });
        return output;
    }

    return (
        <div className="pt-5">
            <div className="sm:flex sm:items-center">

                <div className="sm:flex-auto">
                    {/* <h1 className="text-xl font-semibold text-gray-900">Competitors</h1> */}
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all of your competitors on Twitter
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none inline-flex items-center">
                    <div className="mt-1 pr-1">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            onChange={e => setCompetitorHandle(e.target.value)}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="@my_competitor"
                        />
                    </div>
                    <button
                        onClick={() => {
                            //console.log(itsBeen15MinutesSinceLastAdd());
                            
                            // console.log(competitorAlreadyExists());
                            if(!competitorHandle || competitorHandle.trim()===""){
                                // console.log("n");
                                toast("Please enter a competitor handle", { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                return;
                            }

                            if(competitorAlreadyExists()){
                                // console.log("n");
                                toast("Competitor Already Exists", { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                return;
                            }

                            // if(!itsBeen15MinutesSinceLastAdd()) {
                            //     toast("Due To Twitter API Limits, Allow a 15 minute interval when Adding Competitors", { hideProgressBar: true, autoClose: 5000, type: 'error' })
                            //     return;
                            // }

                            toast("Please Wait, Adding Competitor "+competitorHandle, { hideProgressBar: true, autoClose: 10000, type: 'info' })
                            let addCompetitor = httpsCallable(functions, 'addCompetitor');
                            addCompetitor({
                                competitorHandle: competitorHandle,
                                twitterAccountAccessToken: auth.user['oauthAccessToken'],
                                twitterAccountAccessSecret: auth.user['oauthTokenSecret'],
                                campaignId: selectedCampaignID
                            })
                            .then((result) => {
                                console.log(result.data);
                                toast("Follower extraction will start in ~ 60 seconds", { hideProgressBar: false, autoClose: 60000, type: 'success' })
                                setCompetitorHandle("")

                                if (result.data?.error) {
                                    toast(result.data?.error, { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                    return;
                                }
                            }).catch(e=>{
                                console.log(e);
                                toast("API Limits Reached, Please try again in 15 minutes", { hideProgressBar: true, autoClose: 5000, type: 'error' })
                            });
                        }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 sm:py-2 py-3 text-xs md:text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Add Competitor
                    </button>
                </div>
            </div>

            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Name
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Followers
                                        </th>
                                        <th scope="col" className="hidden lg:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Status
                                        </th>
                                        {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Role
                                        </th> */}
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {competitors.map((person) => (
                                        <tr key={person.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                <div className="flex items-center">
                                                    <div className="h-10 w-10 flex-shrink-0">
                                                        <img className="h-10 w-10 rounded-full" src={person.profile_image_url} alt="" />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="font-medium text-gray-900">{person.name}</div>
                                                        <div className="text-gray-500">@{person.username}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <div className="grid grid-cols-2">
                                                    <div>
                                                        <div className="text-gray-900"><b>{person.public_metrics?.followers_count}</b> Followers - <b>{competitorExtractedFollowersWithOpenDMs?.[person['id']] || 0 }</b> Found with Open DMs</div>
                                                        {/* {person?.nextCursor!="0" && <div className="text-gray-500">Follower Extraction {Number(person?.extractedCount * 100 / person.public_metrics?.followers_count).toFixed(0)}% ~ ETA {((person.public_metrics?.followers_count-person?.extractedCount)/200).toFixed(0)} Min</div>} */}
                                                        {!person?.dmCheckComplete && <div className="text-gray-500">Follower Extraction {Number((competitorExtractedFollowers?.[person['id']] || 0) * 100 / person.public_metrics?.followers_count).toFixed(0)}% ~ ETA {(((((person.public_metrics?.followers_count)-(competitorExtractedFollowers?.[person['id']] || 0))/10)+5)/60).toFixed(0)} Hours</div>}
                                                        {!person?.dmCheckComplete && <div className="text-gray-500"><b>{competitorExtractedFollowers?.[person['id']] || 0 }</b> Followers Extracted</div>}
                                                        {person?.dmCheckComplete && <div className="text-gray-500">100% Extracted</div>}
                                                    </div>
                                                    {/* <div>
                                                        <button
                                                            type="button"
                                                            onClick={()=>{
                                                                history?.push('/engage');
                                                            }}
                                                            className="inline-flex items-center mr-3 rounded-full border border-transparent bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            <EnvelopeIcon className="h-5 w-5" aria-hidden="true" />
                                                        </button>
                                                    </div> */}
                                                </div>
                                                
                                                
                                            </td>
                                            <td className="hidden lg:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                { person?.extractedCount && <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                    Ready To DM Followers
                                                </span>}
                                                { !person?.extractedCount && <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                    Pending
                                                </span>}
                                            </td>
                                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td> */}
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        

                                                        let removeCompetitor = httpsCallable(functions, 'removeCompetitor');
                                                        removeCompetitor({
                                                            competitorID: person.id,
                                                            twitterAccountAccessToken: auth.user['oauthAccessToken'],
                                                            twitterAccountAccessSecret: auth.user['oauthTokenSecret']
                                                        })
                                                            .then((result) => {
                                                                console.log(result.data);
                                                                competitorExtractedFollowers[person['id']] = 0;
                                                                setCompetitorExtractedFollowers({...competitorExtractedFollowers})
                                                                toast("Removed "+person['username'], { hideProgressBar: true, autoClose: 5000, type: 'success' })
                                                                if (result.data?.error) {
                                                                    toast(result.data?.error, { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                                                    return;
                                                                }
                                                            });
                                                    }}
                                                    className="inline-flex items-center rounded-full border border-transparent bg-rose-600 p-1 text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                                                >
                                                    <TrashIconMini
                                                        className="h-5 w-5" aria-hidden="true" />
                                                </button>
                                                
                                                
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            {competitors.length == 0 && <div className="text-center">
                <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                </svg>
                <h2 className="mt-2 text-lg font-medium text-gray-900">Add Competitors</h2>
                <p className="mt-1 text-sm text-gray-500">
                    You haven’t added any competitors yet. Once you add a competitor you can start finding leads through their followers
                </p>
            </div>}
        </div>
    )
}
