import React, { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const TableFooter = ({ range, setPage, page, slice }) => {
  const [selectedPage, setSelectedPage] = useState(1)

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
      setSelectedPage(page)
    }
  }, [slice, page, setPage]);
  return (
    <div className="flex items-center justify-center">


      <div>
        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <a
            onClick={() => {
              if(selectedPage===1) return;
              setPage(selectedPage-1)
              setSelectedPage(selectedPage-1)
            }}
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </a>
          {[...(new Set([...range.slice(0, 7),...range.slice(range.length - 7)]))].map((el, index) => (
            <a
            key={index}
            onClick={() => {
              setPage(el)
              setSelectedPage(el)
            }}
            // aria-current="page"
            className={ selectedPage==Number(el) ? "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20": "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"}
            
          >
            {el}
          </a>
          ))}
          <a
            onClick={() => {
              if(selectedPage==range.length) return;
              setPage(selectedPage+1)
              setSelectedPage(selectedPage+1)
            }}
            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </a>
          
          
        </nav>
      </div>
    </div>
  );
};

export default TableFooter;