import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { rtdb } from "../../hooks/firebase";
import { useAuth } from "../../lib/auth";

const stats = [
  { name: 'DMs Sent Today', stat: '0/50' },
  { name: 'Avg. DMs Daily', stat: '0' },
  { name: 'Avg. Response Rate', stat: '0%' },
]

export default function Stats() {

  //Access firebase database to get stats
  const [dailyDMs, setDailyDMs] = useState("-");
  const auth = useAuth()


  useEffect(() => {
    const userObject = ref(rtdb, `users/${auth?.user?.localId}/DMUsage/` + new Date().toJSON().slice(0, 10));
    onValue(userObject, (snapshot) => {
      if (snapshot.exists()) {
        const sum = Object.values(snapshot.val()).reduce((a, b) => a + b, 0);
        
        setDailyDMs(sum);
      } else {
        setDailyDMs(0);
      }
    });
  }, [])


  return (
    <div>
      {/* <h3 className="text-lg font-medium leading-6 text-gray-900">Last 30 days</h3> */}
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">DMs Sent Today</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{dailyDMs}/50</dd>
        </div>
        {/* <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">Reply Rate</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">5%</dd>
        </div> */}
        {/* <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">Name</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">5</dd>
        </div> */}
      </dl>
    </div>
  )
}
