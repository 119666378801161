import React, { useState } from "react";
import useTable from "../../hooks/useTable";
import TableFooter from "./TableFooter";
import { ProfileCard } from "./searchResultProfileCard";

const SearchResultsTable = ({ data, rowsPerPage, competitor, selling, selectedTemplate, selectedAccount, selectedCampaign }) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <>
      {/* <TableFooter range={range} slice={slice} setPage={setPage} page={page} /> */}
      {slice.map((person) => (
        <ProfileCard selectedCampaign={selectedCampaign} selling={selling} key={person.id} person={person} competitor={competitor} selectedTemplate={selectedTemplate} selectedAccount={selectedAccount}/>
      ))}
      {data.length !== 0 && <TableFooter range={range} slice={slice} setPage={setPage} page={page} />}
    </>
  );
};

export default SearchResultsTable;