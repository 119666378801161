import { functions, httpsCallable, rtdb } from '../../hooks/firebase'
import { useAuth } from '../../lib/auth';
import { toast } from "react-toastify";
import { useEffect, useState } from 'react';
import { get, push, ref, set } from 'firebase/database';
import PleaseWait from '../modals/please-wait';



export function ProfileCard({
    person,
    competitor,
    selling,
    selectedTemplate,
    selectedAccount,
    selectedCampaign
}) {

    const auth = useAuth();
    const [promoting, setPromoting] = useState(selling)
    const [{ content, carriage }, setContent] = useState({ content: '', carriage: 0 })
    const [AiDM, setAiDM] = useState("")
    const [dmText, setDMText] = useState(selectedTemplate.text.replace('{{display_name}}', person['name'].split(' ')[0]));

    // create two states for autolike and autoFollow
    const [autoLike, setAutoLike] = useState(true);
    const [autoFollow, setAutoFollow] = useState(true);

    const [pleaseWait, setPleaseWait] = useState(false);

    // const [sellingSentence, setSellingSentence] = useState(selling);

    useEffect(() => {
        // console.log(content);
        if (carriage === AiDM.length) return
        const delay = setTimeout(() => {
            setContent({ content: content + AiDM[carriage], carriage: carriage + 1 })
            setDMText(content)
            clearTimeout(delay)
        }, 10)
        // eslint-disable-next-line
    }, [content, AiDM])

    useEffect(() => {
        // console.log(content);
        console.log("Selling vhanged");
        setPromoting(selling)

        // eslint-disable-next-line
    }, [selling])


    useEffect(() => {
        setDMText(selectedTemplate.text.replace('{{display_name}}', person['name'].split(' ')[0]));
    }, [selectedTemplate])


    function sendDMToUser(){
        if (!dmText || dmText.trim() === "") {
            toast('DM Text is empty ', { hideProgressBar: true, autoClose: 2000, type: 'error' });
            return;
        }
        const sendDmRef = ref(rtdb, `backgroundProcesses/${auth?.user?.localId}/engage`);
        push(sendDmRef, {
            competitorId: competitor['id'],
            competitorName: competitor['name'],
            personId: person['id_str'],
            screen_name: person['screen_name'],
            dm: dmText,
            postToLike: person['status']?.['id_str'] || null,
            like: autoLike,
            follow: autoFollow,
            dmAccount: selectedAccount.name == auth?.user?.displayName ? null : selectedAccount,
            imageUrl: person['profile_image_url'],
        }).then(() => {
            toast('DM for ' + person['name'] + ' will be sent shortly after a like and a follows', { hideProgressBar: true, autoClose: 2000, type: 'success' });
        });

        const dmSendByCRM = ref(rtdb, '/users/' + auth?.user?.localId + '/competitorFollowers/' + competitor['id'] + '/' + person['id_str'] + '/dmSentByCRM');
        set(dmSendByCRM, true);
        checkIfTheUserExistInOtherCompetitorsOfTheSameCampaign(person['id_str'],markUserAsSentDMIfExist);
    }

    function markUserAsSentDMIfExist(competitorId, userID){
        // console.log(competitorId, userID);
        const userRef = ref(rtdb, `users/${auth?.user?.localId}/competitorFollowers/` + competitorId + `/${userID}`);
        get(userRef).then((snapshot) => {
            if (snapshot.exists()) {
                console.log("User exist in another competitor");
                console.log(`users/${auth?.user?.localId}/competitorFollowers/` + competitorId + `/${userID}`);
                set(ref(rtdb, `users/${auth?.user?.localId}/competitorFollowers/` + competitorId + `/${userID}/dmSentByCRM`), true);
            } else {
                // console.log("No User Available");
            }
        });
    }


    function hideUser() {
        console.log(`users/${auth?.user?.localId}/competitorFollowers/` + competitor['id'] + `/${person['id_str']}/isHidden`);
        const isHiddenRef = ref(rtdb, `users/${auth?.user?.localId}/competitorFollowers/` + competitor['id'] + `/${person['id_str']}/isHidden`);
        set(isHiddenRef, true).then(() => {
            toast('User Hidden: ' + person['name'], { hideProgressBar: true, autoClose: 2000, type: 'success' });

            checkIfTheUserExistInOtherCompetitorsOfTheSameCampaign(person['id_str'],removeUserIfExist);
        });
    }

    function checkIfTheUserExistInOtherCompetitorsOfTheSameCampaign(userID, executeFunction){
        const competitorFollowersRef = ref(rtdb, `users/${auth?.user?.localId}/competitors/`);
        get(competitorFollowersRef).then((snapshot) => {
            if (snapshot.exists()) {
                const competitors = snapshot.val();
                
                Object.keys(competitors).forEach((key) => {
                    console.log(competitors[key]);
                    if(competitors[key]['campaignId'] === selectedCampaign['id'] && key!=competitor['id']){
                        executeFunction(key,userID)
                    }
                });
            } else {
                console.log("No data available");
            }
        });
    }

    function removeUserIfExist(competitorId, userID){
        // console.log(competitorId, userID);
        const userRef = ref(rtdb, `users/${auth?.user?.localId}/competitorFollowers/` + competitorId + `/${userID}`);
        get(userRef).then((snapshot) => {
            if (snapshot.exists()) {
                console.log("User exist in another competitor");
                console.log(`users/${auth?.user?.localId}/competitorFollowers/` + competitorId + `/${userID}`);
                set(ref(rtdb, `users/${auth?.user?.localId}/competitorFollowers/` + competitorId + `/${userID}/isHidden`), true);
            } else {
                // console.log("No User Available");
            }
        });
    }

    return (
        <div key={person.id} className="max-w-3xl w-full mx-auto z-10">
            <div className="flex flex-col">
                <div className="bg-white border shadow rounded-md p-4 m-4">
                    <div className="flex-none sm:flex">
                        <div className=" relative h-15 w-15   sm:mb-0 mb-3">
                            <img src={person.profile_image_url} alt="aji" className=" w-15 h-15 object-cover rounded-2xl" />
                            {/* <span className="inline-flex items-center rounded-full bg-pink-100 px-3 py-0.5 text-sm font-medium text-pink-800">
                                Badge
                            </span> */}
                            {/* <a href="#"
              className="absolute -right-2 bottom-2   -ml-3  text-white p-1 text-xs bg-green-400 hover:bg-green-500 font-medium tracking-wider rounded-full transition ease-in duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                className="h-4 w-4">
                <path
                  d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z">
                </path>
              </svg>
            </a> */}
                        </div>
                        <div className="flex-auto sm:ml-5 justify-evenly">
                            <div className="flex items-center justify-between sm:mt-2">
                                <div className="flex items-center">
                                    <div className="flex flex-col">
                                        <div onClick={(e) => {
                                            window.open("https://twitter.com/" + person['screen_name'], '_blank', 'noopener,noreferrer');
                                        }} className="w-full flex-none text-lg hover:cursor-pointer hover:text-sky-700 text-gray-800 font-bold leading-none">{person.name}</div>

                                        <div className="flex-auto text-gray-500 my-1">
                                            {/* <span className="mr-3 ">UI/UX Designer</span><span className="mr-3 border-r border-gray-200  max-h-0"></span><span>Cochin, IND</span> */}
                                            <span>
                                                {person.description}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row items-center">
                                {/* <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  className="h-5 w-5 text-yellow-500">
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                  </path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  className="h-5 w-5 text-yellow-500">
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                  </path>
                </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  className="h-5 w-5 text-yellow-500">
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                  </path>
                </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  className="h-5 w-5 text-yellow-500">
                  <path
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                  </path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" className="h-5 w-5 text-yellow-500">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z">
                  </path>
                </svg>
              </div> */}
                                {/* <div className="flex-1 inline-flex  hidden items-center">
                <img className="w-5 h-5" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDY0IDY0IiBoZWlnaHQ9IjY0cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA2NCA2NCIgd2lkdGg9IjY0cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0zMiw3LjE3NEMxOC4zMTEsNy4xNzQsNy4xNzQsMTguMzExLDcuMTc0LDMyYzAsMTMuNjg5LDExLjEzNywyNC44MjYsMjQuODI2LDI0LjgyNmMxMy42ODksMCwyNC44MjYtMTEuMTM3LDI0LjgyNi0yNC44MjYgIEM1Ni44MjYsMTguMzExLDQ1LjY4OSw3LjE3NCwzMiw3LjE3NHogTTM4LjE3NCwzMi44NzRoLTQuMDM5YzAsNi40NTMsMCwxNC4zOTgsMCwxNC4zOThoLTUuOTg1YzAsMCwwLTcuODY4LDAtMTQuMzk4aC0yLjg0NXYtNS4wODggIGgyLjg0NXYtMy4yOTFjMC0yLjM1NywxLjEyLTYuMDQsNi4wNC02LjA0bDQuNDMzLDAuMDE3djQuOTM5YzAsMC0yLjY5NSwwLTMuMjE5LDBjLTAuNTI0LDAtMS4yNjgsMC4yNjItMS4yNjgsMS4zODZ2Mi45OWg0LjU2ICBMMzguMTc0LDMyLjg3NHoiLz48L3N2Zz4=" />
                <img className="w-5 h-5" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDU2LjY5MyA1Ni42OTMiIGhlaWdodD0iNTYuNjkzcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1Ni42OTMgNTYuNjkzIiB3aWR0aD0iNTYuNjkzcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yOC4zNDgsNS4xNTdjLTEzLjYsMC0yNC42MjUsMTEuMDI3LTI0LjYyNSwyNC42MjVjMCwxMy42LDExLjAyNSwyNC42MjMsMjQuNjI1LDI0LjYyM2MxMy42LDAsMjQuNjIzLTExLjAyMywyNC42MjMtMjQuNjIzICBDNTIuOTcxLDE2LjE4NCw0MS45NDcsNS4xNTcsMjguMzQ4LDUuMTU3eiBNNDAuNzUyLDI0LjgxN2MwLjAxMywwLjI2NiwwLjAxOCwwLjUzMywwLjAxOCwwLjgwM2MwLDguMjAxLTYuMjQyLDE3LjY1Ni0xNy42NTYsMTcuNjU2ICBjLTMuNTA0LDAtNi43NjctMS4wMjctOS41MTMtMi43ODdjMC40ODYsMC4wNTcsMC45NzksMC4wODYsMS40OCwwLjA4NmMyLjkwOCwwLDUuNTg0LTAuOTkyLDcuNzA3LTIuNjU2ICBjLTIuNzE1LTAuMDUxLTUuMDA2LTEuODQ2LTUuNzk2LTQuMzExYzAuMzc4LDAuMDc0LDAuNzY3LDAuMTExLDEuMTY3LDAuMTExYzAuNTY2LDAsMS4xMTQtMC4wNzQsMS42MzUtMC4yMTcgIGMtMi44NC0wLjU3LTQuOTc5LTMuMDgtNC45NzktNi4wODRjMC0wLjAyNywwLTAuMDUzLDAuMDAxLTAuMDhjMC44MzYsMC40NjUsMS43OTMsMC43NDQsMi44MTEsMC43NzcgIGMtMS42NjYtMS4xMTUtMi43NjEtMy4wMTItMi43NjEtNS4xNjZjMC0xLjEzNywwLjMwNi0yLjIwNCwwLjg0LTMuMTJjMy4wNjEsMy43NTQsNy42MzQsNi4yMjUsMTIuNzkyLDYuNDgzICBjLTAuMTA2LTAuNDUzLTAuMTYxLTAuOTI4LTAuMTYxLTEuNDE0YzAtMy40MjYsMi43NzgtNi4yMDUsNi4yMDYtNi4yMDVjMS43ODUsMCwzLjM5NywwLjc1NCw0LjUyOSwxLjk1OSAgYzEuNDE0LTAuMjc3LDIuNzQyLTAuNzk1LDMuOTQxLTEuNTA2Yy0wLjQ2NSwxLjQ1LTEuNDQ4LDIuNjY2LTIuNzMsMy40MzNjMS4yNTctMC4xNSwyLjQ1My0wLjQ4NCwzLjU2NS0wLjk3NyAgQzQzLjAxOCwyMi44NDksNDEuOTY1LDIzLjk0Miw0MC43NTIsMjQuODE3eiIvPjwvc3ZnPg==" />
                <img className="w-5 h-5" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNjdweCIgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDY3IDY3OyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNjcgNjciIHdpZHRoPSI2N3B4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cGF0aCBkPSJNNTAuODM3LDQ4LjEzN1YzNi40MjVjMC02LjI3NS0zLjM1LTkuMTk1LTcuODE2LTkuMTk1ICBjLTMuNjA0LDAtNS4yMTksMS45ODMtNi4xMTksMy4zNzRWMjcuNzFoLTYuNzljMC4wOSwxLjkxNywwLDIwLjQyNywwLDIwLjQyN2g2Ljc5VjM2LjcyOWMwLTAuNjA5LDAuMDQ0LTEuMjE5LDAuMjI0LTEuNjU1ICBjMC40OS0xLjIyLDEuNjA3LTIuNDgzLDMuNDgyLTIuNDgzYzIuNDU4LDAsMy40NCwxLjg3MywzLjQ0LDQuNjE4djEwLjkyOUg1MC44Mzd6IE0yMi45NTksMjQuOTIyYzIuMzY3LDAsMy44NDItMS41NywzLjg0Mi0zLjUzMSAgYy0wLjA0NC0yLjAwMy0xLjQ3NS0zLjUyOC0zLjc5Ny0zLjUyOHMtMy44NDEsMS41MjQtMy44NDEsMy41MjhjMCwxLjk2MSwxLjQ3NCwzLjUzMSwzLjc1MywzLjUzMUgyMi45NTl6IE0zNCw2NCAgQzE3LjQzMiw2NCw0LDUwLjU2OCw0LDM0QzQsMTcuNDMxLDE3LjQzMiw0LDM0LDRzMzAsMTMuNDMxLDMwLDMwQzY0LDUwLjU2OCw1MC41NjgsNjQsMzQsNjR6IE0yNi4zNTQsNDguMTM3VjI3LjcxaC02Ljc4OXYyMC40MjcgIEgyNi4zNTR6IiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7ZmlsbDojMDEwMTAxOyIvPjwvc3ZnPg==" />

              </div> */}

                            </div>
                            <div className="flex pt-2  text-sm text-gray-500">

                                <div className="flex-1 inline-flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z">
                                        </path>
                                    </svg>
                                    <p className="">{person.followers_count} Followers</p>
                                </div>
                                <div className="flex-1 inline-flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fillRule="evenodd"
                                            d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                    <p className="">{person.statuses_count} Tweets</p>
                                </div>
                                {person['dmSentByCRM'] && <div className="flex-1 inline-flex items-center">
                                    <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800">
                                        DM Sent
                                    </span>
                                </div>}
                                {person['following'] && <div className="flex-1 inline-flex items-center">
                                    <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800">
                                        Following
                                    </span>
                                </div>}

                                <button

                                    type="button"
                                    onClick={() => {
                                        if (!promoting || promoting.trim() === "") {
                                            toast('Please describe your product at the top of the page.', { hideProgressBar: true, autoClose: 2000, type: 'error' });
                                            return;
                                        }
                                        setAiDM("")
                                        setContent({ content: '', carriage: 0 })
                                        let generateDM = httpsCallable(functions, 'generateDM');
                                        setPleaseWait(true)
                                        generateDM({
                                            bio: person.description,
                                            promoting: promoting,
                                            name: person.name,
                                            twitterAccountAccessToken: auth.user['oauthAccessToken'],
                                            twitterAccountAccessSecret: auth.user['oauthTokenSecret']
                                        })
                                            .then((result) => {
                                                setPleaseWait(false);
                                                console.log(result.data);

                                                if (result.data?.error) {
                                                    toast(result.data?.error, { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                                    return;
                                                }

                                                setAiDM(result.data.text.trim())
                                                toast(result.data.usageCount + " AI tokens used today", { hideProgressBar: true, autoClose: 5000, type: 'success' })
                                                // setText(result.data.text.trim())
                                                // setDm(result.data.text.trim())

                                                // toast("Added "+competitorHandle, { hideProgressBar: true, autoClose: 5000, type: 'success' })
                                                // setCompetitorHandle("")

                                                // if (result.data?.error) {
                                                //     toast(result.data?.error, { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                                //     return;
                                                // }
                                            }).catch(e => {
                                                console.log(e);
                                                toast("API Limits Reached, Please try again in 15 minutes", { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                            });
                                    }}
                                    className="mx-1 inline-flex items-center rounded-md border border-transparent bg-gray-800 text-white px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 focus:ring-offset-2"
                                >
                                    Generate AI Intro
                                </button>
                                
                                <button
                                    type="button"
                                    onClick={() => {
                                        hideUser();
                                    }}
                                    className="mx-1 inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                >
                                    Hide User
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="px-4 py-5 sm:p-6">
                            {/* <h3 className="text-lg font-medium leading-6 text-gray-900">Update your email</h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>Change the email address you want associated with your account.</p>
                            </div> */}
                            {/* <div className=" sm:flex sm:items-center justify-center">
                                <div className="w-full sm:max-w-md mr-5">
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                                            I'm Selling A ...
                                        </span>
                                        <input
                                            type="text"
                                            value={promoting}
                                            onChange={e => setPromoting(e.target.value)}
                                            name="company-website"
                                            id="company-website"
                                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="An AI based Twitter Cold DM Tool "
                                        />
                                    </div>
                                </div>
                                <button

                                    type="button"
                                    onClick={() => {
                                        setAiDM("")
                                        setContent({ content: '', carriage: 0 })
                                        let generateDM = httpsCallable(functions, 'generateDM');
                                        generateDM({
                                            bio: person.description,
                                            promoting: promoting,
                                            name: person.name,
                                            twitterAccountAccessToken: auth.user['oauthAccessToken'],
                                            twitterAccountAccessSecret: auth.user['oauthTokenSecret']
                                        })
                                            .then((result) => {
                                                console.log(result.data);

                                                if (result.data?.error) {
                                                    toast(result.data?.error, { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                                    return;
                                                }

                                                setAiDM(result.data.text.trim())
                                                toast(result.data.usageCount + " AI tokens used today", { hideProgressBar: true, autoClose: 5000, type: 'success' })
                                                // setText(result.data.text.trim())
                                                // setDm(result.data.text.trim())

                                                // toast("Added "+competitorHandle, { hideProgressBar: true, autoClose: 5000, type: 'success' })
                                                // setCompetitorHandle("")

                                                // if (result.data?.error) {
                                                //     toast(result.data?.error, { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                                //     return;
                                                // }
                                            }).catch(e => {
                                                console.log(e);
                                                toast("API Limits Reached, Please try again in 15 minutes", { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                            });
                                    }}
                                    className="mr-3 inline-flex items-center rounded-md border border-transparent bg-gray-800 text-white px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 focus:ring-offset-2"
                                >
                                    Generate AI Intro
                                </button>
                            </div> */}
                        </div>
                        <div className="-m-0.5 rounded-lg p-0.5">
                            <label htmlFor="dm" className="sr-only">
                                DM
                            </label>
                            <div>
                                <textarea
                                    rows={9}
                                    name="dm"
                                    id="dm"
                                    value={dmText}
                                    onChange={e => setDMText(e.target.value)}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Try our AI our type in your own cold DM..."

                                />
                            </div>
                        </div>
                        <div className="mt-2 flex ">
                            {/* <fieldset className="space-y-5">
                                <legend className="sr-only">Options</legend>
                                <div className="relative flex items-start">
                                    <div className="flex h-5 items-center">
                                        <input
                                            id="like"
                                            onChange={e => setAutoLike(e.target.checked)}
                                            checked={autoLike}
                                            aria-describedby="like-description"
                                            name="like"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="like" className="font-medium text-gray-700">
                                            Like
                                        </label>
                                        <span id="like-description" className="text-gray-500">
                                            <span className="sr-only">Like </span> their last tweet before sending DM.
                                        </span>
                                    </div>
                                </div>
                                <div className="relative flex items-start">
                                    <div className="flex h-5 items-center">
                                        <input
                                            id="follow"
                                            onChange={e => setAutoFollow(e.target.checked)}
                                            checked={autoFollow}
                                            aria-describedby="follow-description"
                                            name="follow"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="follow" className="font-medium text-gray-700">
                                            Follow
                                        </label>
                                        <span id="follow-description" className="text-gray-500">
                                            <span className="sr-only">Follow </span> them before DM.
                                        </span>
                                    </div>
                                </div>
                                
                            </fieldset> */}



                        </div>
                        <div className="mt-2 flex justify-end">
                        <button
                                    onClick={() => {
                                        sendDMToUser();

                                    }}
                                    className="mx-1 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Send DM
                                </button>
                        </div>

                    </div>
                </div>
            </div>
            <PleaseWait isOpen={pleaseWait} setPleaseWait={setPleaseWait} name={person['name']} />
        </div>)
}