import { functions, httpsCallable, rtdb } from '../../hooks/firebase'
import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { ref, onValue, set, query, orderByChild, equalTo } from "firebase/database";
import { useAuth } from '../../lib/auth';
import { toast } from 'react-toastify';
import BatchFollowDetails from './batch-follow-deatails';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function BatchFollow({
    selectedCampaign
}) {
    const auth = useAuth();
    const [competitors, setCompetitors] = useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState([])
    const [dailyLimit, setDailyLimit] = useState(30)
    const [autoLike, setAutoLike] = useState(false)
    const [dailyCount, setDailyCount] = useState()
    const [batchFollowEnabled, setBatchFollowEnabled] = useState(false);

    useEffect(() => {

        const competitorCountRef = query(ref(rtdb, `users/${auth?.user?.localId}/competitors`),orderByChild('campaignId'),equalTo(selectedCampaign['id']))
        onValue(competitorCountRef, (snapshot) => {
            const data = snapshot.val();
            let competitorsUpdated = [];
            if (!data) {
                setCompetitors([]);
                return;
            };
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(data)) {
                // console.log(value);
                competitorsUpdated.push(value)
            }
            //console.log(competitorsUpdated);
            setCompetitors([...competitorsUpdated])
        });


        // eslint-disable-next-line
    }, [selectedCampaign])

    useEffect(() => {



        if (selectedCompetitor['id']) {
            console.log("Selected Competitor Change", selectedCompetitor);
            const competitorCountRef = ref(rtdb, `backgroundProcesses/${auth?.user?.localId}/batchFollow/${selectedCompetitor['id']}`);
            onValue(competitorCountRef, (snapshot) => {
                const data = snapshot.val();
                console.log(data);
                if (!data) {
                    setBatchFollowEnabled(false);
                    setDailyCount(null)
                    return;
                }

                if (data['dailyLimit']) {
                    setDailyLimit(Number(data['dailyLimit']))
                    console.log("Batch Follow Enabled");
                    setBatchFollowEnabled(true);
                }
                if (data['likeTheLastTweet']) setAutoLike(data['likeTheLastTweet'])

                setDailyCount(data['dailyCount']);

            }, (error) => {
                console.log(error);
            });

        }

        // eslint-disable-next-line
    }, [selectedCompetitor])

    function getTotalFollowed() {
        let count = 0;
        for (const day in dailyCount) {
            count += dailyCount[day];
        }
        return count;
    }

    function getETAToFinishBatchFollow() {
        let toFollow = selectedCompetitor["public_metrics"]?.["followers_count"] - getTotalFollowed();
        let hoursToGo = toFollow / dailyLimit;
        return hoursToGo.toFixed(0);
    }

    return (<div className="py-6">
        <div className="mx-auto max-w-7xl pb-5 px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Batch Follow</h1>
        </div>

        {true && <div className="mx-auto pb-5 max-w-7xl px-4 sm:px-6 md:px-8">
            <RadioGroup value={selectedCompetitor} onChange={setSelectedCompetitor}>
                <RadioGroup.Label className="text-base font-medium text-gray-900">Select a Competitor</RadioGroup.Label>

                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    {competitors.map((competitorAccount) => (
                        <RadioGroup.Option
                            key={competitorAccount.id}
                            value={competitorAccount}
                            disabled={!competitorAccount?.dmCheckComplete}
                            className={({ checked, active }) =>
                                classNames(
                                    checked ? 'border-transparent' : 'border-gray-300',
                                    active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                                    !competitorAccount?.dmCheckComplete ? 'cursor-not-allowed  bg-gray-200' : 'bg-white cursor-pointer',
                                    'relative flex rounded-lg border p-4 shadow-sm focus:outline-none'
                                )
                            }
                        >
                            {({ checked, active }) => (
                                <>
                                    <span className="flex flex-1">
                                        <span className="flex flex-col">
                                            <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                {competitorAccount.name}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                @{competitorAccount.username}
                                            </RadioGroup.Description>
                                            {competitorAccount?.dmCheckComplete && <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                                {competitorAccount.extractedCount} valid followers available out of {competitorAccount.public_metrics?.followers_count}
                                            </RadioGroup.Description>}
                                            {!competitorAccount?.dmCheckComplete && <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                                Extraction {Number((competitorAccount?.dmStatusCheckQty || 0) * 100 / competitorAccount.public_metrics?.followers_count).toFixed(0)}% ~ ETA {((((competitorAccount.public_metrics?.followers_count) - (competitorAccount?.dmStatusCheckQty || 0)) / 10) + 15).toFixed(0)} Min
                                            </RadioGroup.Description>}
                                        </span>
                                    </span>
                                    <CheckCircleIcon
                                        className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                        aria-hidden="true"
                                    />
                                    <span
                                        className={classNames(
                                            active ? 'border' : 'border-2',
                                            checked ? 'border-indigo-500' : 'border-transparent',
                                            'pointer-events-none absolute -inset-px rounded-lg'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>}
        {selectedCompetitor.length !== 0 && <div className="mx-auto pb-5 max-w-7xl px-4 sm:px-6 md:px-8">
            <label className="text-base font-medium text-gray-900">Enable/Disable Batch-Follow</label>
            <div className="shadow sm:overflow-hidden sm:rounded-md mt-5">
                <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                    {/* <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can recieve mail.</p>
                        </div> */}

                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Daily Limit (Set to 30/day per Twitter Regulations)
                            </label>
                            <input
                                type="number"
                                value={dailyLimit}
                                name="dailyLimit"
                                onChange={e => setDailyLimit(e.target.value)}
                                disabled
                                id="dailyLimit"
                                placeholder="20"
                                autoComplete="Daily Limit"
                                className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm"
                            />
                        </div>

                        {/* <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                    Last name
                                </label>
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-4">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <input
                                    type="text"
                                    name="email-address"
                                    id="email-address"
                                    autoComplete="email"
                                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <select
                                    id="country"
                                    name="country"
                                    autoComplete="country-name"
                                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                >
                                    <option>United States</option>
                                    <option>Canada</option>
                                    <option>Mexico</option>
                                </select>
                            </div>

                            <div className="col-span-6">
                                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                    Street address
                                </label>
                                <input
                                    type="text"
                                    name="street-address"
                                    id="street-address"
                                    autoComplete="street-address"
                                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    autoComplete="address-level2"
                                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                    State / Province
                                </label>
                                <input
                                    type="text"
                                    name="region"
                                    id="region"
                                    autoComplete="address-level1"
                                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                    ZIP / Postal code
                                </label>
                                <input
                                    type="text"
                                    name="postal-code"
                                    id="postal-code"
                                    autoComplete="postal-code"
                                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div> */}

                    </div>
                    <div className="mt-4 space-y-4">
                        <div className="flex items-start">
                            <div className="flex h-5 items-center">
                                <input
                                    id="likeTheLastTweet"
                                    name="likeTheLastTweet"
                                    type="checkbox"
                                    checked={autoLike}
                                    onChange={e => setAutoLike(e.target.checked)}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                            </div>
                            <div className="ml-3 text-sm">
                                <label htmlFor="neverSentDM" className="font-medium text-gray-700">
                                    Auto-Like Most Recent Tweet
                                </label>
                                <p className="text-gray-500">The most recent tweet will be auto-liked after following the user</p>
                            </div>
                        </div>
                        {/* <div>
                                <div className="flex items-start">
                                    <div className="flex h-5 items-center">
                                        <input
                                            id="candidates"
                                            name="candidates"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="candidates" className="font-medium text-gray-700">
                                            Candidates
                                        </label>
                                        <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                                    </div>
                                </div>
                            </div> */}
                        {/* <div>
                                <div className="flex items-start">
                                    <div className="flex h-5 items-center">
                                        <input
                                            id="offers"
                                            name="offers"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="offers" className="font-medium text-gray-700">
                                            Offers
                                        </label>
                                        <p className="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    {batchFollowEnabled && <button
                        type="button"
                        onClick={() => {
                            // await admin.database().ref().set(dailyLimit);
                            const batchFollowRef = ref(rtdb, 'backgroundProcesses/'+auth?.user?.localId + '/batchFollow/'+selectedCompetitor.id);
                            // get(child(ref(rtdb), `users/${user.uid}`));
                            set(batchFollowRef,null).then((done)=>{
                                console.log("Done");
                            });
                            // batchFollowRef.
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 mx-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                        Disable Auto-Follow
                    </button>}
                    <button
                        type="button"
                        onClick={() => {
                            //

                            if (dailyLimit < 0 || dailyLimit > 30) {
                                toast("Please set a daily limit between 1-30", { hideProgressBar: true, autoClose: 5000, type: 'info' })
                                return;
                            }
                            // console.log(selectedCompetitor);
                            let setBatchFollow = httpsCallable(functions, 'setBatchFollow');
                            setBatchFollow({
                                competitorID: selectedCompetitor['id'],
                                dailyLimit: dailyLimit,
                                likeTheLastTweet: autoLike
                            })
                                .then((result) => {
                                    console.log(result.data);
                                    toast("Batch Follow Set for " + selectedCompetitor["name"], { hideProgressBar: true, autoClose: 5000, type: 'success' })
                                    // setCompetitorHandle("")

                                    // if (result.data?.error) {
                                    //     toast(result.data?.error, { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                    //     return;
                                    // }
                                }).catch(e => {
                                    console.log(e);
                                    toast("API Limits Reached, Please try again in 15 minutes", { hideProgressBar: true, autoClose: 5000, type: 'error' })
                                });
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        {batchFollowEnabled && "Update"}{!batchFollowEnabled && "Enable"} Auto-Follow
                    </button>
                </div>
            </div>
        </div>}
        <div className="mx-auto max-w-2xl md:text-center">
            {/* {true && <h5 className="font-display text-2xl tracking-tight text-slate-900 sm:text-2xl">
                    Combed through 1K (free tier limit) of @{10} followers
                </h5>} */}
            {batchFollowEnabled && selectedCompetitor["public_metrics"] && <h5 className="font-display text-2xl tracking-tight text-slate-900 sm:text-2xl">
                Followed <b>{getTotalFollowed(dailyCount)}</b> out of {selectedCompetitor["public_metrics"]?.["followers_count"]}. <br /> ETA <b>{getETAToFinishBatchFollow()}</b> Hours
            </h5>}
            {!batchFollowEnabled && selectedCompetitor["public_metrics"] && <h5 className="font-display text-2xl tracking-tight text-slate-900 sm:text-2xl">
                Once Enabled, will take {getETAToFinishBatchFollow()} hour(s) to complete. 
            </h5>}
        </div>

        {dailyCount && <BatchFollowDetails dailyCount={dailyCount} />}
    </div>)
}