import { useEffect } from "react";
import { useState } from "react";

import { functions, httpsCallable, rtdb } from '../../hooks/firebase'
import { ref, child, onValue, push, update  } from "firebase/database";
import { useAuth } from "../../lib/auth";
import { useHistory } from "react-router";

/* This example requires Tailwind CSS v3.0+ */
export default function Onboarding() {

    //create three states for first name, last name, and email
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [campaign, setCampaign] = useState();
    const [phone, setPhone] = useState();
    const auth = useAuth()
    const history = useHistory();

    function completeOnboarding() {
        console.log(firstName, lastName, email, campaign);

        const defaultCampaign = ref(rtdb, `campaigns/${auth?.user?.localId}`);
        push(defaultCampaign, {
            name: campaign,
            createdAt: Date.now(),
            updatedAt: Date.now(),
            userId: auth?.user?.localId,
        }).then((result) => {
            //use the key to
            const campaignId = result.key
            const campaignDMsRef = ref(rtdb, `users/${auth?.user?.localId}/DMTemplates`);
            push(campaignDMsRef, {
                name: 'Default DM',
                createdAt: Date.now(),
                updatedAt: Date.now(),
                text: "Hello {{display_name}}, my name is [Name] and I am reaching out from <my company name>. We specialize in providing a comprehensive suite of business services designed to help companies reach their full potential. I noticed that you're active on Twitter and thought you might be interested in learning more about our offerings.",
            })
            push(campaignDMsRef, {
                name: 'Default DM2',
                createdAt: Date.now(),
                updatedAt: Date.now(),
                text: 'Hi {{display_name}}, I hope this message finds you well. My name is [Name] and I am reaching out from <my company name>, a company that provides a wide range of business services to help companies achieve their goals. I noticed your presence on Twitter and thought you might be interested in learning more about our services.',
            })
        });

        const userObject = ref(rtdb, `users/${auth?.user?.localId}`);
        update(userObject, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
        }).then(() => {
            console.log('updated user object')
            history.push('/competitors');
        });
    }


    return (
        <div className="bg-white h-screen">
            <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                        <br />
                        Tell us a little about yourself
                    </h2>
                    {/* <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                        Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur
                        commodo do ea.
                    </p> */}
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <div
                            className="flex flex-col gap-y-4"
                        >
                            <input
                                type="text"
                                onChange={(e) => setFirstName(e.target.value)}
                                className="border border-gray-300 rounded-md px-4 py-2"
                                placeholder="First name"
                            />
                            <input
                                type="text"
                                onChange={(e) => setLastName(e.target.value)}
                                className="border border-gray-300 rounded-md px-4 py-2"
                                placeholder="Last name"
                            />
                            <input
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="border border-gray-300 rounded-md px-4 py-2"
                                placeholder="Email"
                            />
                            <input
                                type="text"
                                onChange={(e) => setPhone(e.target.value)}
                                className="border border-gray-300 rounded-md px-4 py-2"
                                placeholder="Phone Number"
                            />
                            <input
                                type="text"
                                onChange={(e) => setCampaign(e.target.value)}
                                className="border border-gray-300 rounded-md px-4 py-2"
                                placeholder="First Campaign Name"
                            />
                        </div>
                    </div>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href="#/"
                            onClick={completeOnboarding}
                            className="rounded-md bg-indigo-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Get started
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
