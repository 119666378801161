import { rtdb } from '../../hooks/firebase'
import { Fragment, useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { ref, onValue, query, orderByChild, equalTo, startAfter, set } from "firebase/database";
import { useAuth } from '../../lib/auth';
import SearchResultsTable from './searchResultsTable';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const accounts = [
    {
        id: 1,
        name: 'Wade Cooper',
        avatar:
            'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function Engage({
    selectedCampaign
}) {
    const auth = useAuth();
    const [competitors, setCompetitors] = useState([]);
    // const [selectedMethod, setSelectedMethod] = useState();
    const [selectedCompetitor, setSelectedCompetitor] = useState([])
    const [searchResults, setSearchResults] = useState(null)
    const [keywords, setKeywords] = useState("");
    const [neverDMd, setNeverDMd] = useState(true);
    const [following, setFollowing] = useState(true);
    const [hundredPlusFollowers, setHundredPlusFollowers] = useState(true);
    const [selling, setSelling] = useState("");
    const [dmOpenOnly, setDMOpenOnly] = useState(true);
    const [showHiddenUsers, setShowHiddenUsers] = useState(false);
    const [competitorExtractedFollowers, setCompetitorExtractedFollowers] = useState({})
    const [accounts, setAccounts] = useState([])
    const [selectedAccount, setSelectedAccount] = useState(null)

    const [DMTemplates, setDMTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState([]);

    // const methodForEnagement = [
    //     { id: 'competitors', title: 'Followers From Competitor', disabled: false },
    //     { id: 'customList', title: 'A Custom List', disabled: true },
    // ]


    useEffect(() => {

        const competitorCountRef = query(ref(rtdb, `users/${auth?.user?.localId}/competitors`), orderByChild('campaignId'), equalTo(selectedCampaign['id']))
        onValue(competitorCountRef, (snapshot) => {
            const data = snapshot.val();
            let competitorsUpdated = [];
            if (!data) {
                setCompetitors([]);
                return;
            };
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(data)) {
                // console.log(value);
                competitorsUpdated.push(value)
            }
            console.log(competitorsUpdated);
            setCompetitors([...competitorsUpdated])
        });

        const sellingRef = ref(rtdb, `users/${auth?.user?.localId}/selling`);
        onValue(sellingRef, (snapshot) => {
            const data = snapshot.val();
            
            if(data!=selling) {
                setSelling(data)
                console.log(data);
            }
        });


        // eslint-disable-next-line
    }, [selectedCampaign])

    useEffect(() => {
        //fore each competitor get followers with DMs open
        // console.log(competitors);
        competitors.forEach(competitor => {

            console.log(competitor);

            const userObject = query(ref(rtdb, `/users/${auth?.user?.localId}/competitorFollowers/${competitor['id']}`), orderByChild('can_dm'), startAfter(null));
            onValue(userObject, (snapshot) => {

                const data = snapshot.val();
                // console.log(data);
                //get number of keys in data object
                let count = 0;
                for (const [key, value] of Object.entries(data)) {
                    count++;
                }
                // console.log(count);
                competitorExtractedFollowers[competitor['id']] = count;
                setCompetitorExtractedFollowers({ ...competitorExtractedFollowers })

            })
        });


    }, [competitors.length])

    useEffect(() => {

        executeSearch("")
        // eslint-disable-next-line
    }, [selectedCompetitor, dmOpenOnly, neverDMd])

    useEffect(() => {
        console.log("DM Tempalte Use Effect");
        const campaignDMsRef = ref(rtdb, `users/${auth?.user?.localId}/DMTemplates`);
        onValue(campaignDMsRef, (snapshot) => {

            let localDMTemplates = []
            snapshot.forEach((childSnapshot) => {
                let dmTemplate = childSnapshot.val();
                dmTemplate['id'] = childSnapshot.key;
                localDMTemplates.push(dmTemplate)
            })

            console.log(localDMTemplates);
            setDMTemplates([...localDMTemplates])
            setSelectedTemplate(localDMTemplates[0] || null)
            

        });
    }, [])

    useEffect(() => {
        
        onValue(ref(rtdb, `users/${auth?.user?.localId}/accounts`), (snapshot) => {
            
            //console.log(snapshot.val())
            let newAccounts = [];
            newAccounts.push({
                id: 1,
                name: auth?.user?.displayName,
                avatar: auth?.user?.photoURL
            });
            
            if(snapshot.val() === null) {
                setAccounts([...newAccounts])   
                setSelectedAccount(newAccounts[newAccounts.length - 1])
                return;
            }
            
            for (const [key, value] of Object.entries(snapshot.val())) {
                // console.log(value);
                if(value['campaignID']==selectedCampaign.id) newAccounts.push({
                    id: value['id'],
                    name: value['name'],
                    avatar: value['profile_image_url'],
                    username: value['username']
                });
            }
            setAccounts([...newAccounts])
            setSelectedAccount(newAccounts[newAccounts.length - 1])
            
        });
    }, [selectedCampaign])


    function executeSearch(keywords) {
        //console.log(neverDMd);

        if (selectedCompetitor['id'] == null) return;
        //console.log(selectedCompetitor);
        const queryFollowersRef = ref(rtdb, `users/${auth?.user?.localId}/competitorFollowers/` + selectedCompetitor.id);
        onValue(queryFollowersRef, (snapshot) => {
            const data = snapshot.val();
            console.log("Followers are" + selectedCompetitor);
            // console.log(data);
            // let i = 0;
            let localSearchResults = [];
            // eslint-disable-next-line
            for (const [key, user] of Object.entries(data)) {
                // console.log(value);
                // competitorsUpdated.push(value)
                // console.log(++i);
                let lowercaseDesc = (user.description || "").toLowerCase() || "";
                let keywordArray = keywords.trim().toLowerCase().split(',');

                let addedToResults = false;
                keywordArray.forEach(currentKeyword => {

                    if (lowercaseDesc.includes(currentKeyword.trim()) && !addedToResults) {

                        if (user['dmSentByCRM'] && neverDMd) return;
                        if (!user['can_dm'] && dmOpenOnly) return;
                        // if (!user['following'] && following) return;
                        if (user['followers_count'] < 100 && hundredPlusFollowers) return;
                        if (user['isHidden'] && !showHiddenUsers) return;
                        localSearchResults.push(user);
                        addedToResults = true;
                    }
                });

            }
            console.log(localSearchResults?.length);
            console.log(searchResults?.length);
            setSearchResults([...localSearchResults]);

        });
    }

    //write a useEffect that monitors selling but only update the database after 5 seconds of no change
    useEffect(() => {
        console.log("Selling Use Effect");
        console.log(selling);
        if (selling === null || selling=="") return;
        const sellingRef = ref(rtdb, `users/${auth?.user?.localId}/selling`);
        set(sellingRef, selling);
    }, [selling])
    

    return (
        <div className="py-6">
            <div className="mx-auto max-w-7xl pb-5 px-4 sm:px-6 lg:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">Engage</h1>
            </div>

            {true && <div className="mx-auto pb-5 max-w-7xl px-4 sm:px-6 md:px-8">
                <RadioGroup value={selectedCompetitor} onChange={setSelectedCompetitor}>
                    <RadioGroup.Label className="text-base font-medium text-gray-900">Select a Competitor</RadioGroup.Label>

                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                        {competitors.map((competitorAccount) => (
                            <RadioGroup.Option
                                key={competitorAccount.id}
                                value={competitorAccount}
                                // disabled={!competitorAccount?.dmCheckComplete}
                                className={({ checked, active }) =>
                                    classNames(
                                        checked ? 'border-transparent' : 'border-gray-300',
                                        active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                                        // !competitorAccount?.dmCheckComplete ? 'cursor-not-allowed  bg-gray-200' : 'bg-white cursor-pointer',
                                        'relative flex rounded-lg border p-4 shadow-sm focus:outline-none bg-white cursor-pointer'
                                    )
                                }
                            >
                                {({ checked, active }) => (
                                    <>
                                        <span className="flex flex-1">
                                            <span className="flex flex-col">
                                                <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                    {competitorAccount.name}
                                                </RadioGroup.Label>
                                                <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                    @{competitorAccount.username}
                                                </RadioGroup.Description>
                                                {competitorAccount?.dmCheckComplete && <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                                    {competitorAccount.extractedCount} valid followers available out of {competitorAccount.public_metrics?.followers_count}
                                                </RadioGroup.Description>}
                                                {!competitorAccount?.dmCheckComplete && <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                                                    Extraction {Number((competitorExtractedFollowers?.[competitorAccount['id']] || 0) * 100 / competitorAccount.public_metrics?.followers_count).toFixed(0)}% ~ ETA {((((((competitorAccount.public_metrics?.followers_count) - (competitorExtractedFollowers?.[competitorAccount['id']] || 0)) / 10) + 15) / 60).toFixed(0))} Hours
                                                </RadioGroup.Description>}
                                                {!competitorAccount?.dmCheckComplete && <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                                                    🦾{competitorExtractedFollowers?.[competitorAccount['id']] || 0} extracted out of {competitorAccount.public_metrics?.followers_count}
                                                </RadioGroup.Description>}
                                            </span>
                                        </span>
                                        <CheckCircleIcon
                                            className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                            aria-hidden="true"
                                        />
                                        <span
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                'pointer-events-none absolute -inset-px rounded-lg'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            </div>}
            {selectedCompetitor.length !== 0 && <div className="mx-auto pb-5 max-w-7xl px-4 sm:px-6 md:px-8">
                <label className="text-base font-medium text-gray-900">Campaign Settings</label>
                <div className="shadow sm:rounded-md mt-5">
                    <div className="space-y-6 bg-white py-6 px-4 sm:p-6">

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Your Product
                                </label>
                                <textarea
                                    rows={3}
                                    name="dm"
                                    id="dm"
                                    maxLength={500}
                                    value={selling}
                                    onChange={e => setSelling(e.target.value)}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="Explain your product and pain points it solves for your audience"

                                />
                            </div>
                        </div>
                        <div className="mt-4 space-y-4">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    {selectedTemplate && <Listbox value={selectedTemplate} onChange={setSelectedTemplate}>
                                        {({ open }) => (
                                            <>
                                                <Listbox.Label className="block text-sm font-medium text-gray-700">Choose A DM Template</Listbox.Label>
                                                <div className="relative mt-1">
                                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                        <span className="flex items-center">
                                                            {/* <img src={selectedAccount.avatar} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" /> */}
                                                            <span className="ml-3 block truncate">{selectedTemplate.name}</span>
                                                        </span>
                                                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </Listbox.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {DMTemplates.map((template) => (
                                                                <Listbox.Option
                                                                    key={template.id}
                                                                    className={({ active }) =>
                                                                        classNames(
                                                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                        )
                                                                    }
                                                                    value={template}
                                                                >
                                                                    {({ selected, active }) => (
                                                                        <>
                                                                            <div className="flex items-center">
                                                                                {/* <img src={person.avatar} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" /> */}
                                                                                <span
                                                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                                                >
                                                                                    {template.name}
                                                                                </span>
                                                                            </div>

                                                                            {selected ? (
                                                                                <span
                                                                                    className={classNames(
                                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                    )}
                                                                                >
                                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            ))}
                                                        </Listbox.Options>
                                                    </Transition>
                                                </div>
                                            </>
                                        )}
                                    </Listbox>}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    {selectedAccount && <Listbox value={selectedAccount} onChange={setSelectedAccount}>
                                        {({ open }) => (
                                            <>
                                                <Listbox.Label className="block text-sm font-medium text-gray-700">Choose a Twitter Account</Listbox.Label>
                                                <div className="relative mt-1">
                                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                        <span className="flex items-center">
                                                            <img src={selectedAccount.avatar} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                                            <span className="ml-3 block truncate">{selectedAccount.name}<b>{selectedAccount.username ? " @"+selectedAccount.username : ""}</b></span>
                                                        </span>
                                                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        </span>
                                                    </Listbox.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            {accounts.map((person) => (
                                                                <Listbox.Option
                                                                    key={person.id}
                                                                    className={({ active }) =>
                                                                        classNames(
                                                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                        )
                                                                    }
                                                                    value={person}
                                                                >
                                                                    {({ selected, active }) => (
                                                                        <>
                                                                            <div className="flex items-center">
                                                                                <img src={person.avatar} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                                                                <span
                                                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                                                >
                                                                                    {person.name} <b>{person.username ? " @"+person.username : ""}</b>
                                                                                </span>
                                                                            </div>

                                                                            {selected ? (
                                                                                <span
                                                                                    className={classNames(
                                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                    )}
                                                                                >
                                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Listbox.Option>
                                                            ))}
                                                        </Listbox.Options>
                                                    </Transition>
                                                </div>
                                            </>
                                        )}
                                    </Listbox>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {selectedCompetitor.length !== 0 && <div className="mx-auto pb-5 max-w-7xl px-4 sm:px-6 md:px-8">
                <label className="text-base font-medium text-gray-900">Filter and Search</label>
                <div className="shadow sm:overflow-hidden sm:rounded-md mt-5">
                    <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
                        {/* <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can recieve mail.</p>
                        </div> */}

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Keywords
                                </label>
                                <input
                                    type="text"
                                    name="keywords"
                                    onChange={e => setKeywords(e.target.value)}
                                    id="keywords"
                                    placeholder="building, SaaS"
                                    autoComplete="given-name"
                                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>

                        </div>
                        <div className="mt-4 space-y-4">
                            {/* <div>
                                <div className="flex items-start">
                                    <div className="flex h-5 items-center">
                                        <input
                                            id="dmOpenOnly"
                                            name="dmOpenOnly"
                                            type="checkbox"
                                            checked={dmOpenOnly}
                                            onChange={e => setDMOpenOnly(e.target.checked)}
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label htmlFor="candidates" className="font-medium text-gray-700">
                                            DM's Open
                                        </label>
                                        <p className="text-gray-500">Only show the people that have DM's open</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex items-start">
                                <div className="flex h-5 items-center">
                                    <input
                                        id="neverSentDM"
                                        name="neverSentDM"
                                        type="checkbox"
                                        checked={neverDMd}
                                        onChange={e => setNeverDMd(e.target.checked)}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="neverSentDM" className="font-medium text-gray-700">
                                        Never DM'd
                                    </label>
                                    <p className="text-gray-500">Only show People that you never sent a message</p>
                                </div>
                            </div>

                            <div className="flex items-start">
                                <div className="flex h-5 items-center">
                                    <input
                                        id="following"
                                        name="following"
                                        type="checkbox"
                                        checked={hundredPlusFollowers}
                                        onChange={e => setHundredPlusFollowers(e.target.checked)}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="neverSentDM" className="font-medium text-gray-700">
                                        100+ Followers
                                    </label>
                                    <p className="text-gray-500">Only show People that have 100 or more followers</p>
                                </div>
                            </div>

                            {/* <div className="flex items-start">
                                <div className="flex h-5 items-center">
                                    <input
                                        id="following"
                                        name="following"
                                        type="checkbox"
                                        checked={showHiddenUsers}
                                        onChange={e => setShowHiddenUsers(e.target.checked)}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="neverSentDM" className="font-medium text-gray-700">
                                        Show Hidden Users
                                    </label>
                                    <p className="text-gray-500">Show Users that were previosuly Hidden</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                            type="button"
                            onClick={() => executeSearch(keywords)}
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>}

            {searchResults && <div className="mx-auto max-w-2xl md:text-center">
                {/* {true && <h5 className="font-display text-2xl tracking-tight text-slate-900 sm:text-2xl">
                    Combed through 1K (free tier limit) of @{10} followers
                </h5>} */}
                <h5 className="font-display text-2xl tracking-tight text-slate-900 sm:text-2xl">
                    Found {searchResults.length} People with DMs open!
                </h5>
            </div>}
            <SearchResultsTable selectedCampaign={selectedCampaign} selling={selling} data={searchResults || []} rowsPerPage={50} competitor={selectedCompetitor} selectedTemplate={selectedTemplate} selectedAccount={selectedAccount} />
        </div>
    )
}