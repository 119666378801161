export function Help() {
    return (
        <div className="py-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">Thank You!</h1>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-5">
            <p dir="auto">Twitter DMs are an effective channel for lead generation because they offer a direct and personal way to communicate with potential customers.  Unlike email or other forms of outreach, DMs have a higher chance of being seen and responded to, particularly if they are personalized and relevant to the recipient. By leveraging the power of Twitter and using tools like DirectMarketer.ai, businesses can connect with high-quality leads and build valuable relationships in a fraction of the time it used to take.<br />Heres how to get started:</p>
            <p dir="auto"> </p>
            <blockquote>
                <p dir="auto"><strong>Step 1 - Add Accounts</strong>:<br />Add your Twitter competitors, industry publications, related brands, or any other Twitter accounts that your target audience might be following.Our AI will analyze all the followers of the accounts you added and find the ones that best match your target audience.</p>
                <p dir="auto"> </p>
                <p dir="auto"><strong>Step 2 - Engage:</strong><br />Review the results and compose messages using templates or AI to craft personalized messages to each potential lead. Our AI will then interact with each user just like a human, then send the personalized message, boosting the chances of a response.</p>
                <p dir="auto"> </p>
                <p dir="auto"><strong>Step 3. Review your Twitter DMs:</strong><br />When you get a response, engage in a conversation to them to turn them into customers, followers, etc.</p>
            </blockquote>
            <p dir="auto">By using our service, you can save hours of manual work finding leads on Twitter and increase your chances of converting potential leads into loyal customers.<br />Finally, here's some tips to get the most out of your experience:</p>
            <p dir="auto"> </p>
            <ul>
                <li><strong>Personalize Your DMs</strong></li>
            </ul>
            <p dir="auto">Personalization is key when it comes to DMs. Instead of sending out generic messages to a large list of prospects, take the time to craft unique messages that are tailored to each individual prospect. Use their name, mention something specific about their business or recent activity, or reference a common connection. The more you can make the message feel like it was written specifically for that person, the more likely they are to respond.</p>
            <p dir="auto"> </p>
            <ul>
                <li><strong>Keep it Short and Sweet</strong></li>
            </ul>
            <p dir="auto">In a world where people are bombarded with hundreds of messages a day, it's important to get to the point quickly. Your DM should be concise and to the point, focusing on one clear benefit or value proposition. Don't make prospects work hard to figure out what you want from them or why they should respond.</p>
            <p dir="auto"> </p>
            <ul>
                <li><strong>Offer Value</strong></li>
            </ul>
            <p dir="auto">Make sure your DM offers some kind of value to the prospect. This could be in the form of information, a resource, or a discount. The more you can help the prospect understand what's in it for them, the more likely they are to respond.</p>
            <p dir="auto"> </p>
            <ul>
                <li><strong>Be Respectful of Their Time</strong></li>
            </ul>
            <p dir="auto">Remember, the people you're reaching out to are busy, so don't waste their time. Be respectful of their schedule and be mindful of how often you're reaching out. Don't send multiple DMs in a row or follow up too soon if you haven't received a response.</p>
             <p dir="auto"> </p>
            <ul>
                <li><strong>Make it Easy for Them to Respond</strong></li>
            </ul>
            <p dir="auto">: Your DM should make it easy for prospects to respond. Whether you're offering them a chance to schedule a call or simply asking a question, make sure the next step is clear and straightforward. If you can, include a call to action that makes it easy for them to take the next step.</p>
            <p dir="auto"> </p>
            <p dir="auto">If you keep these best practices in mind, you can increase your chances of getting a response and ultimately converting more prospects into customers. With DirectMarketer.ai, you can automate the process of finding, messaging and tracking prospects, saving time and increasing the efficiency of sales outreach, all while following these best practices for writing effective cold DMs.</p>
            </div>
            </div>)
}