import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusIcon, ClockIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { functions, httpsCallable, rtdb } from '../../hooks/firebase'
import { ref, child, onValue, push, update, set, get, } from "firebase/database";
import { useAuth } from "../../lib/auth";

export default function WelcomeTip({

}) {
    const [open, setOpen] = useState(false)
    const auth = useAuth()
    const cancelButtonRef = useRef(null)
    const [campaign, setCampaign] = useState();

    useEffect(() => {
        console.log('useEffect WelocomTip');
        //setOpen(!open);
        // const userObject = ref(rtdb, `users/${auth?.user?.localId}/showWelcome/` + new Date().toJSON().slice(0, 10));
        // get(userObject, (snapshot) => {
        //     console.log(snapshot.val());

        // });

        const userRef = ref(rtdb, `users/${auth?.user?.localId}/showWelcome/` + new Date().toJSON().slice(0, 10));
        get(userRef).then((snapshot) => {
            if (snapshot.exists()) {
                setOpen(false)

            } else {
                setOpen(true)
                set(userRef, true)

            }
        });
    }, [])

    // useEffect(() => {
    //     if (!open) {
    //         setPleaseWait(false)
    //     }
    // }, [open])


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <InformationCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">

                                        {/* //Start Here */}
                                        <p dir="auto">Thanks you for signing up! Twitter DMs are an effective channel for lead generation because they offer a direct and personal way to communicate with potential customers.</p>
<p dir="auto"> </p>
<p dir="auto"> </p>
<p dir="auto">Unlike email or other forms of outreach, DMs have a higher chance of being seen and responded to, particularly if they are personalized and relevant to the recipient. By leveraging the power of Twitter and using tools like <a title="smartCard-inline" href="http://directmarketer.ai/" rel="noreferrer ugc nofollow" target="_blank">http://DirectMarketer.ai</a>, businesses can connect with high-quality leads and build valuable relationships in a fraction of the time it used to take.<br />Heres how to get started:</p>
<p dir="auto"> </p>
<blockquote>
<p dir="auto"><strong>Step 1 - Add Accounts</strong>:<br />Add your Twitter competitors, industry publications, related brands, or any other Twitter accounts that your target audience might be following.Our AI will analyze all the followers of the accounts you added and find the ones that best match your target audience.</p>
<p dir="auto"> </p>
<p dir="auto"><strong>Step 2 - Engage:</strong><br />Review the results and compose messages using templates or AI to craft personalized messages to each potential lead. Our AI will then interact with each user just like a human, then send the personalized message, boosting the chances of a response.</p>
<p dir="auto"> </p>
<p dir="auto"><strong>Step 3. Review your Twitter DMs:</strong><br />When you get a response, engage in a conversation to them to turn them into customers, followers, etc.</p>
</blockquote>
<p dir="auto">By using our service, you can save hours of manual work finding leads on Twitter and increase your chances of converting potential leads into loyal customers.<br />Finally, here's some tips to get the most out of your experience:</p>
<p dir="auto"> </p>
<ul>
<li><strong>Personalize Your DMs</strong></li>
</ul>
<p dir="auto">Personalization is key when it comes to DMs. Instead of sending out generic messages to a large list of prospects, take the time to craft unique messages that are tailored to each individual prospect. Use their name, mention something specific about their business or recent activity, or reference a common connection. The more you can make the message feel like it was written specifically for that person, the more likely they are to respond.</p>
<p dir="auto"> </p>
<ul>
<li><strong>Keep it Short and Sweet</strong></li>
</ul>
<p dir="auto">In a world where people are bombarded with hundreds of messages a day, it's important to get to the point quickly. Your DM should be concise and to the point, focusing on one clear benefit or value proposition. Don't make prospects work hard to figure out what you want from them or why they should respond.</p>
<p dir="auto"> </p>
<ul>
<li><strong>Offer Value</strong></li>
</ul>
<p dir="auto">Make sure your DM offers some kind of value to the prospect. This could be in the form of information, a resource, or a discount. The more you can help the prospect understand what's in it for them, the more likely they are to respond.</p>
<p dir="auto"> </p>
<ul>
<li><strong>Be Respectful of Their Time</strong></li>
</ul>
<p dir="auto">Remember, the people you're reaching out to are busy, so don't waste their time. Be respectful of their schedule and be mindful of how often you're reaching out. Don't send multiple DMs in a row or follow up too soon if you haven't received a response.</p>
<p dir="auto"> </p>
<p dir="auto">Make it Easy for Them to Respond: Your DM should make it easy for prospects to respond. Whether you're offering them a chance to schedule a call or simply asking a question, make sure the next step is clear and straightforward. If you can, include a call to action that makes it easy for them to take the next step.</p>
<p dir="auto"> </p>
<p dir="auto">If you keep these best practices in mind, you can increase your chances of getting a response and ultimately converting more prospects into customers. With <a title="‌" href="http://directmarkter.ai/" rel="noreferrer ugc nofollow" target="_blank">DirectMarkter.ai</a>, you can automate the process of finding, messaging and tracking prospects, saving time and increasing the efficiency of sales outreach, all while following these best practices for writing effective cold DMs.</p>
<p dir="auto">Again, thanks you for signing up!<br /><a title="‌" href="http://www.directmarketer.ai/" rel="noreferrer ugc nofollow" target="_blank">DirectMarketer.a</a>i</p>
                                        {/* End HERE */}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
