import React, { useState, useEffect, useContext, createContext } from 'react'
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    TwitterAuthProvider,
    onAuthStateChanged,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    setDoc,
    doc,
    getDoc,
} from "firebase/firestore";

import { getDatabase, ref, child, get } from "firebase/database";

import { auth } from '../hooks/firebase';

const firebaseConfig = {
    apiKey: "AIzaSyB9x0RFL9cdaCjzA9liLegAKrzMuoNQcb0",
    authDomain: "directmarketer-ai.firebaseapp.com",
    databaseURL: "https://directmarketer-ai-default-rtdb.firebaseio.com",
    projectId: "directmarketer-ai",
    storageBucket: "directmarketer-ai.appspot.com",
    messagingSenderId: "1066991603332",
    appId: "1:1066991603332:web:bcc709ddebdc0276fe3cd1",
    measurementId: "G-BT16NK628T"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const rtdb = getDatabase(app);

const authContext = createContext()

export function AuthProvider({ children }) {
    const auth = useProvideAuth()
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
    return useContext(authContext)
}

function useProvideAuth() {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    const handleUser = async (rawUser, credentials) => {
        // console.log("handle user");
        // console.log("handle user");
        if (rawUser) {
            const user = rawUser;

            const docRef = doc(db, "users", user.uid);

            const data = {
                uid: user.uid,
                displayName: user.displayName,
                ...user['providerData'][0],
                ...credentials
            };

            if (Object.keys(credentials).length === 0) {
                setLoading(false)
                let userObj = await getDoc(docRef);
                // let userRtdb = await get(child(ref(rtdb), `users/${user.uid}`));
                //console.log(userRtdb.val());
                setUser({
                    ...userObj.data(),
                    // ...userRtdb.val() || {}
                })
                // console.log();
            } else {
                await setDoc(docRef, data)
                setLoading(false)
                setUser(data)
                console.log(data);
            }

            return data
        } else {
            setLoading(false)
            setUser(false)
            return false
        }
    }

    const signinWithTwitter = () => {
        const twitterProvider = new TwitterAuthProvider();
        setLoading(true)
        return signInWithPopup(auth, twitterProvider)
            .then((response) => handleUser(response.user, response['_tokenResponse']))
    }

    const signout = () => {
        return signOut(auth)
            .then(() => handleUser(false, {}))
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            handleUser(user, {})
        })

        return () => unsubscribe()
    }, [])

    return {
        user,
        loading,
        signinWithTwitter,
        signout,
    }
}

const formatUser = (user) => {
    return {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
        provider: user.providerData[0].providerId,
        photoUrl: user.photoURL,
    }
}