import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import { functions, httpsCallable, rtdb } from '../../hooks/firebase'
import { ref, child, onValue, push, update  } from "firebase/database";
import { useAuth } from "../../lib/auth";

export default function NewCampaign({
    isOpen,
    setNewCampaignModal
}) {
    const [open, setOpen] = useState(isOpen)
    const auth = useAuth()
    const cancelButtonRef = useRef(null)
    const [campaign, setCampaign] = useState();

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    useEffect(() => {
        if (!open) {
            setNewCampaignModal(false)
        }
    }, [open])


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <PlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Add a New Campaign
                                        </Dialog.Title>
                                        <div className="mt-2">

                                            <form>

                                                <div className="mx-1">
                                                    <input
                                                        placeholder='Campaign Name'
                                                        onChange={(e) => setCampaign(e.target.value)}
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                        onClick={() => {
                                            const defaultCampaign = ref(rtdb, `campaigns/${auth?.user?.localId}`);
                                            push(defaultCampaign, {
                                                name: campaign,
                                                createdAt: Date.now(),
                                                updatedAt: Date.now(),
                                                userId: auth?.user?.localId,
                                            })
                                            // .then((result) => {
                                            //     //use the key to
                                            //     const campaignId = result.key
                                            //     const campaignDMsRef = ref(rtdb, `campaigns/${auth?.user?.localId}/DMTemplates`);
                                            //     push(campaignDMsRef, {
                                            //         name: 'Default DM',
                                            //         createdAt: Date.now(),
                                            //         updatedAt: Date.now(),
                                            //         text: 'Hello {{display_name}}, this is a default DM template. You can edit this template by clicking the edit button on the campaign page.',
                                            //     })
                                            //     push(campaignDMsRef, {
                                            //         name: 'Default DM2',
                                            //         createdAt: Date.now(),
                                            //         updatedAt: Date.now(),
                                            //         text: 'Hello {{display_name}}, this is a default DM template. You can edit this template by clicking the edit button on the campaign page.',
                                            //     })
                                            // });
                                            setOpen(false)
                                        }}
                                    >
                                        Add
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
