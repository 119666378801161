import { Fragment, useEffect, useState } from 'react'
import { ChatBubbleLeftEllipsisIcon, TagIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import { useAuth } from '../../lib/auth';
import { limitToFirst, limitToLast, onValue, orderByChild, query, ref } from 'firebase/database';
import { rtdb } from '../../hooks/firebase';
import moment from "moment";


const activity = [
    {
        id: 1,
        type: 'dm',
        person: { name: 'Eduardo Benz', href: '#' },
        imageUrl:
            'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
        dm:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ',
        date: '6d ago',
    },
    {
        id: 2,
        type: 'follow',
        person: { name: 'Hilary Mahy', href: '#' },
        assigned: { name: 'Kristin Watson', href: '#' },
        date: '2d ago',
    },
    {
        id: 3,
        type: 'like',
        person: { name: 'Hilary Mahy', href: '#' },
        tags: [
            { name: 'Bug', href: '#', color: 'bg-rose-500' },
            { name: 'Accessibility', href: '#', color: 'bg-indigo-500' },
        ],
        date: '6h ago',
    },
    {
        id: 4,
        type: 'dm',
        person: { name: 'Jason Meyers', href: '#' },
        imageUrl:
            'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
        dm:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.',
        date: '2h ago',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Feed() {

    const [feedActivity, setFeedActivity] = useState(activity);
    const auth = useAuth();

    useEffect(() => {
        console.log("Feed");
        setFeedActivity([]);
        //get reference to order by child value
        
        const userObject = query(ref(rtdb, `completedEngage/${auth?.user?.localId}`), orderByChild('executedAt'), limitToLast(3));
        //get onValue to order by a child value
        
        onValue(userObject, (snapshot) => {
            let localFeedActivity = [];
            // if (snapshot.exists()) {
            //     console.log(snapshot.val());
            // } 

            //iterate through the object and add to feedActivity
            snapshot.forEach((childSnapshot) => {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                // console.log(childData);
                // console.log();

                let likeEvent ={
                    id: childSnapshot.key+(Math.random() + 1).toString(36).substring(7),
                    type: 'like',
                    person: { name: childData['screen_name'], href: '#' },
                    tags: [
                        { name: 'Bug', href: '#', color: 'bg-rose-500' },
                        { name: 'Accessibility', href: '#', color: 'bg-indigo-500' },
                    ],
                    date: moment(childData['executedAt']).fromNow(),
                }

                let dmEvent = {
                    id: childSnapshot.key+(Math.random() + 1).toString(36).substring(7),
                    type: 'dm',
                    person: { name: childData['screen_name'], href: 'https://twitter.com/'+childData['screen_name'] },
                    imageUrl:
                    childData['imageUrl'] || 'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
                    dm:
                    childData['dm'],
                    date: moment(childData['executedAt']).fromNow(),
                }

                let followEvent = {
                    id: childSnapshot.key+(Math.random() + 1).toString(36).substring(7),
                    type: 'follow',
                    person: { name: 'Hilary Mahy', href: '#' },
                    assigned: { name: childData['screen_name'], href: 'https://twitter.com/'+childData['screen_name'] },
                    date: moment(childData['executedAt']).fromNow(),
                }
                
                localFeedActivity.push(dmEvent);
                localFeedActivity.push(followEvent);
                localFeedActivity.push(likeEvent);
                

                setFeedActivity(localFeedActivity);
            });
        });
        }, [])

        return (
            <div className="flow-root rounded-lg bg-white shadow px-4 py-5 mt-5">
                Recent Feed
                <ul role="list" className="-mb-8 mt-5">
                    {feedActivity.map((activityItem, activityItemIdx) => (
                        <li key={activityItem.id}>
                            <div className="relative pb-8">
                                {activityItemIdx !== activity.length - 1 ? (
                                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                ) : null}
                                <div className="relative flex items-start space-x-3">
                                    {activityItem.type === 'dm' ? (
                                        <>
                                            <div className="relative">
                                                <img
                                                    className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                                    src={activityItem.imageUrl}
                                                    alt=""
                                                />

                                                <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                                    <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                </span>
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <div>
                                                    <div className="text-sm">
                                                        <a href={activityItem.person.href} className="font-medium text-gray-900">
                                                            {activityItem.person.name}
                                                        </a>
                                                    </div>
                                                    <p className="mt-0.5 text-sm text-gray-500">Sent DM {activityItem.date}</p>
                                                </div>
                                                <div className="mt-2 text-sm text-gray-700">
                                                    <p>{activityItem.dm}</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : activityItem.type === 'follow' ? (
                                        <>
                                            <div>
                                                <div className="relative px-1">
                                                    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                                        <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="min-w-0 flex-1 py-1.5">
                                                <div className="text-sm text-gray-500">
                                                    {/* <a href={activityItem.person.href} className="font-medium text-gray-900">
                          {activityItem.person.name}
                        </a>{' '} */}
                                                    Followed{' '}
                                                    <a href={activityItem.assigned.href} className="font-medium text-gray-900">
                                                        {activityItem.assigned.name}
                                                    </a>{' '}
                                                    <span className="whitespace-nowrap">{activityItem.date}</span>
                                                </div>
                                            </div>
                                        </>
                                    ) : activityItem.type === 'like' ? (
                                        <>
                                            <div>
                                                <div className="relative px-1">
                                                    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                                        <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="min-w-0 flex-1 py-0">
                                                <div className="text-sm leading-8 text-gray-500">
                                                    <span className="mr-0.5">
                                                        Liked{' '}
                                                        <a href={activityItem.person.href} className="font-medium text-gray-900">
                                                            {activityItem.person.name}
                                                        </a>
                                                        {' '}Tweet
                                                    </span>{' '}
                                                    <span className="mr-0.5">
                                                        {/* {activityItem.tags.map((tag) => (
                            <Fragment key={tag.name}>
                              <a
                                href={tag.href}
                                className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                              >
                                <span className="absolute flex flex-shrink-0 items-center justify-center">
                                  <span
                                    className={classNames(tag.color, 'h-1.5 w-1.5 rounded-full')}
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3.5 font-medium text-gray-900">{tag.name}</span>
                              </a>{' '}
                            </Fragment>
                          ))} */}
                                                    </span>
                                                    <span className="whitespace-nowrap">{activityItem.date}</span>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
